
import { React, useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector,useDispatch } from 'react-redux';

import { addUser } from '../../redux/user.slice';
import store from '../../redux/store'
import Messagesent from '../../components/messagesent'
import { addAddresses } from '../../redux/address.slice';
import { addHistroy } from '../../redux/backhistroy.slice';

function Login({to, links}) {

    const router = useRouter()
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const create = router.query.create ? router.query.create : false

    const histroy = useSelector((state) => state.histroy);

    useEffect(() => {
        set_show_screen(create ? 'register' : 'login')
    }, [create])

    var token = false
    if (user[0] != undefined) {
        token = user[0].token
    }


    const [show_screen, go_show_screen] = useState('login')
    const [password, set_password] = useState('login')
    const [confirm_password, set_confirm_password] = useState('login')
    const [form_submitted, set_form_submitted] = useState(false)
    const [error, set_error_message] = useState(false)
    const [success, set_success_message] = useState(false)

    const set_success = (message) => {
        set_success_message(message)
    }

    const set_error = (message) => {
        set_error_message(message)
    }

    const set_show_screen = (screen) => {
        set_error(false)
        set_success(false)
        go_show_screen(screen)
    }

    // create a temp place for register password values for confirming and checkign validity


    const validatePassword = (event) => {
        set_password(event.target.value)
        var letterNumber = /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        if (event.target.value.match(letterNumber)) {
            // it passes
            event.target.setCustomValidity("")
            return true
        } else {
            // fails
            event.target.setCustomValidity("Password must contain at least 8 characters - letters, numbers and at least one symbol");
            return false
        }
    }

    const validateConfirmPassword = (event) => {
        set_confirm_password(event.target.value)
        if (event.target.value !== password) {
            event.target.setCustomValidity("Password doesnt Match");
            return false
        } else {
            // it passes
            event.target.setCustomValidity("")
            return true
        }

    }

    const submit_and_validate_register_form = (event) => {
        event.preventDefault()
        var chk_status = event.target.checkValidity();
        event.target.reportValidity();
        if (chk_status) {
            register_api_call(event)
        }
    }

    const submit_and_validate_credit_account_form = (event) => {
        event.preventDefault()
        var chk_status = event.target.checkValidity();
        event.target.reportValidity();
        if (chk_status) {
            var result = formsmailer_api_call(event)
        }
        if (result) {
            set_form_submitted(true)
        }
    }


    const set_active_addresses = (user) => {

        const billing = user.addresses.find((item) => item.active_billing == 1);
        const shipping = user.addresses.find((item) => item.active_delivery == 1);

        var data = { billing: billing, shipping: shipping }

        // save to our data store / state tree
        store.dispatch(addAddresses(data))
    }


    const login_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());

        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/login',
            {
                body: JSON.stringify({
                    email: form.email,
                    password: form.password,
                    device_name: 'browser'
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                // set user
                store.dispatch(addUser(data.user))
                // set user addresses
                set_active_addresses(data.user)

                // redirect here
                if (to =="checkout") {
                    router.push('/checkout', undefined, { shallow: true })
                }else if (histroy && histroy[0] == 1) {
                    dispatch(addHistroy(0))
                    router.push('/', undefined, { shallow: true })
                } else {
                    router.back()
                }
            })
            .catch((error) => {
                set_error(error.error);
            });
    }


    const forgot_password_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());

        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/forgotpassword',
            {
                body: JSON.stringify({
                    email: form.email,
                    device_name: 'browser'
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                // what to do here?
                set_success('Password Reset Email Sent');
            })
            .catch((error) => {
                set_error(error.error);
            });
    }

    const register_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());
        const address = [{
            fname: form.fname,
            lname: form.lname,
            address: form.address,
            address2: form.address2,
            city: form.city,
            state: '',
            country: 'GB',
            pcode :form.pcode,
            phone: form.phone.replace(/\D/g,''),
            email : form.email,
            active_billing :1,
            active_delivery:0
        },{
            fname: form.fname,
            lname: form.lname,
            address: form.address,
            address2: form.address2,
            city: form.city,
            state: '',
            country: 'GB',
            pcode :form.pcode,
            phone: form.phone.replace(/\D/g,''),
            email : form.email,
            active_billing :0,
            active_delivery:1
        }];

        // send the email as the email and the name at the remote end
        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/register',
            {
                body: JSON.stringify({
                    name: form.fname + " "+ form.lname,
                    email: form.email,
                    password: form.password,
                    device_name: 'browser',
                    address :JSON.stringify(address),
                    business : 'NA',
                    promo: form.promoConfirm,
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                // set user
                store.dispatch(addUser(data.user))
                // set user address
                set_active_addresses(data.user)
                // redirect here
                if (to =="checkout") {
                    router.push('/checkout/details', undefined, { shallow: true })
                } else {
                    router.back()
                }
            })
            .catch((error) => {
                set_error(error.error);
            });
    }

    const formsmailer_api_call = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);
        const form = Object.fromEntries(data.entries());

        const body_text = (form) => {
            var text = ""
            for (var key of Object.keys(form)) {
                text += (key + " - " + form[key]) + "\n"
            }
            return text
        }

        // send the email as the email and the name at the remote end
        const res = fetch(
            process.env.NEXT_PUBLIC_API_URL + '/formsmailer',
            {
                body: JSON.stringify({
                    body: body_text(form),
                    subject: "Credit Account Application",
                    reply_to: form.email,
                    device_name: 'browser'
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        )
            .then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .catch(async response => {
                const error = await response.json().then(text => text)
                return Promise.reject(error)
            })
            .then(data => {
                return true
                // set_success('Email Sent');
            })
            .catch((error) => {
                set_error(error.error);
            });

    }


    return (
        <>
            <div className="lg:w-1/3 w-full">
                <div className={(links) ? "bg-white shadow rounded-3xl mb-10" : "bg-white shadow rounded-3xl"} >
                    <nav className="tabs grid grid-cols-2">
                        {
                            (show_screen == 'login') ? (
                                <>
                                    <button onClick={() => set_show_screen('login')} className="tab font-bold active text-gray-900 py-4 block focus:outline-none bg-white lg:text-lg text-base rounded-tl-3xl">
                                        Sign In
                                    </button>
                                    <button onClick={() => set_show_screen('register')} className="tab font-bold text-gray-600 py-4 block focus:outline-none lg:text-lg text-base bg-login-tab rounded-tr-3xl hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out">
                                        Create Account
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button onClick={() => set_show_screen('login')} className="tab font-bold text-gray-600 py-4 block focus:outline-none lg:text-lg text-base bg-login-tab rounded-tl-3xl hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out">
                                        Sign In
                                    </button>
                                    <button onClick={() => set_show_screen('register')} className="tab font-bold active text-gray-900 py-4 block focus:outline-none bg-white lg:text-lg text-base rounded-tr-3xl">
                                        Create Account
                                    </button>
                                </>
                            )}

                    </nav>
                    <div id="panels" className="p-8">
                        <div className="panel-1 tab-content active space-y-4">
                            {
                                (show_screen == 'login') ? (
                                    <>
                                        <form method="post" onSubmit={login_api_call}>
                                            <label className="field-label mb-4">
                                                <span className="label-text">E-Mail Address</span>
                                                <input type="email" required name="email" className="input-field w-full" />
                                            </label>
                                            <label className="field-label mb-4">
                                                <div className="grid grid-cols-3">
                                                    <div><span className="">Password</span></div>
                                                    <div className="text-right col-span-2">
                                                        <a onClick={() => set_show_screen('forgot_password')} className="text-link">Forgotten Password?</a>
                                                    </div>
                                                </div>
                                                <input type="password" required name="password" className="input-field w-full" />
                                            </label>
                                            <label className="flex items-center mb-6">
                                                <input className="h-5 w-5 form-checkbox mr-2  text-brand-primary border-gray-300 hover:border-gray-500 focus:ring-brand-primary hover:cursor-pointer" type="checkbox" />
                                                <span className="checkbox-label-text leading-none">Stay signed in?</span>
                                            </label>

                                            <button className="btn btn-primary btn-lg btn-block">Continue</button>


                                        </form>
                                    </>
                                ) : ('')
                            }

                            {
                                (show_screen == 'forgot_password') ? (
                                    <>
                                        <form method="post" onSubmit={forgot_password_api_call}>
                                            <label className="field-label mb-4">
                                                <span className="label-text">E-Mail Address</span>
                                                <input type="email" required name="email" className="input-field w-full" />
                                            </label>

                                            <button className="btn btn-primary btn-lg btn-block">Continue</button>

                                            <div className="text-center mt-4">
                                                <a onClick={() => set_show_screen('login')} className="text-link">Back to Login</a>
                                            </div>
                                        </form>
                                    </>
                                ) : ('')
                            }

                            {
                                (show_screen == 'register') ? (
                                    <>
                                        {/* <div className="flex items-center flex-col justify-center py-5"><i className="icon icon-new-customer w-32 h-32"></i></div>
                                <button className="bg-brand-primary rounded w-full flex items-center px-4 py-3 text-white text-base justify-center font-semibold">Create An Account</button> */}
                                        <div className="">
                                            <h3 className="mb-4 text-brand-primary">Create An Account</h3>
                                            <p className="text-gray-700 text-base font-normal mb-6"><small>Fields marked with an asterix (*) are required.</small></p>
                                            <form method="post" onSubmit={(event) => submit_and_validate_register_form(event)}>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">First Name <span className="text-ttd-red-500">*</span></span>
                                                        <input type="" required name="fname" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Last Name <span className="text-ttd-red-500">*</span></span>
                                                        <input type="" required name="lname" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">E-Mail Address <span className="text-ttd-red-500">*</span></span>
                                                        <input type="email" required name="email" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Address Line 1 <span className="text-ttd-red-500">*</span></span>
                                                        <input type="" required name="address" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Address Line 2</span>
                                                        <input type="" name="address2" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Town / City <span className="text-ttd-red-500">*</span></span>
                                                        <input type="" required name="city" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Postcode <span className="text-ttd-red-500">*</span></span>
                                                        <input type="" required name="pcode" className="input-field" />
                                                    </label>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="field-label">
                                                        <span className="label-text">Phone Number <span className="text-ttd-red-500">*</span></span>
                                                        <input type="" required name="phone" className="input-field" />
                                                        <small className="text-gray-500 leading-none">Only used to contact you if there are issues with delivery or fulfilment.</small>
                                                    </label>
                                                </div>
                                                <label className="field-label mb-4">
                                                    <span className="label-text">Password <span className="text-ttd-red-500">*</span></span>
                                                    <input onChange={(event) => validatePassword(event)} type="password" name="password" required className="input-field w-full" />
                                                    <small className="text-gray-500">Must contain numbers, letters and at least one symbol</small>
                                                </label>
                                                <label className="field-label mb-4">
                                                    <span className="label-text">Confirm Password <span className="text-ttd-red-500">*</span></span>
                                                    <input onChange={(event) => validateConfirmPassword(event)} type="password" name="confirm_password" required className="input-field w-full" />
                                                </label>
                                                <label className="flex mb-6 items-center">
                                                    <input className="h-5 w-5 form-checkbox mr-2 rounded-full text-brand-primary border-gray-300 hover:border-gray-500 focus:ring-brand-primary hover:cursor-pointer" required name="termsConfirm" type="checkbox" />
                                                    <span className="checkbox-label-text leading-none">I agree to the <Link href="/terms"><a className="text-link">terms and conditions</a></Link> <span className="text-ttd-red-500">*</span></span>
                                                </label>
                                                <label className="flex mb-6">
                                                    <input className="h-5 w-5 form-checkbox mr-2 rounded-full text-brand-primary border-gray-300 hover:border-gray-500 focus:ring-brand-primary hover:cursor-pointer" name="promoConfirm" type="checkbox" />
                                                    <span className="checkbox-label-text leading-none">Please keep me informed of any special offers or other promotional activities</span>
                                                </label>
                                                <button className="btn btn-primary btn-lg btn-block">Create Account</button>
                                            </form>
                                        </div>
                                    </>
                                ) : ('')
                            }
                            {
                                (show_screen == 'credit_account') ? (
                                    <>
                                        {(form_submitted) ? (
                                            <Messagesent></Messagesent>
                                        ) : (
                                            <div className="">
                                                <h3 className="mb-4">Request Credit Account</h3>
                                                <p className="text-gray-600 text-base font-normal mb-4">Please use the form below to request a credit account. We manually check all credit requests which means it may take up to 1-2 weeks for an account to be approved.</p>
                                                <p className="text-gray-600 text-base font-normal mb-4">This form will be checked by a human and can typically take 1-2 weeks to approve.</p>
                                                <p className="text-gray-600 text-base font-normal mb-4">If you are looking for a standard account where you can pay instantly through a credit/debit card, then please <a onClick={() => set_show_screen('register')} className="text-brand-primary text-base underline font-semibold">click here.</a>.</p>
                                                <p className="text-gray-600 text-base font-normal"><small>Fields marked with an asterix (*) are required.</small></p>

                                                <form method="post" onSubmit={(event) => submit_and_validate_credit_account_form(event)}>

                                                    <div className="mb-4">
                                                        <label className="field-label">Company Name <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="company" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Customer Type <span className="text-ttd-red-500">*</span></label>
                                                        <select name="business_type" className="appearance-none block w-full py-2 px-3 border border-gray-300 font-normal rounded focus:outline-none text-base text-gray-500">
                                                            <option>Consumer/Homeowner</option>
                                                            <option>Facilities & Property Maintenance</option>
                                                            <option>Installer/Tradesman</option>
                                                            <option>Merchant</option>
                                                            <option>Other</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">First Name <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="fname" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Last Name <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="lname" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">E-Mail Address <span className="text-ttd-red-500">*</span></label>
                                                        <input type="email" name="email" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Address Line 1 <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="address" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Address Line 2</label>
                                                        <input type="" name="address2" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Town / City <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="city" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Postcode <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="pcode" className="input-field" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="field-label">Phone Number <span className="text-ttd-red-500">*</span></label>
                                                        <input type="" name="phone" className="input-field" />
                                                    </div>
                                                    <label className="flex mb-6">
                                                        <input className="h-5 w-5 form-checkbox mr-2 rounded-full text-brand-primary border-gray-300 hover:border-gray-500 focus:ring-brand-primary hover:cursor-pointer" type="checkbox" name="termsConfirm" />
                                                        <span className="checkbox-label-text leading-none">Please keep me informed of any special offers or other promotional activities</span>
                                                    </label>
                                                    <label className="flex mb-6">
                                                        <input className="h-5 w-5 form-checkbox mr-2 rounded-full text-brand-primary border-gray-300 hover:border-gray-500 focus:ring-brand-primary hover:cursor-pointer" type="checkbox" name="promoConfirm" />
                                                        <span className="checkbox-label-text leading-none">I agree to the <Link href="/terms"><a href="" className="text-brand-primary text-base underline font-semibold">terms and conditions</a></Link>.</span>
                                                    </label>
                                                    <button className="bg-brand-primary rounded w-full flex items-center px-4 py-3 text-white text-base justify-center font-semibold">Request Credit Account</button>
                                                </form>
                                            </div>
                                        )}
                                    </>
                                ) : ('')
                            }
                            {(error) ? (<p className="bg-rose-50 border border-rose-300 text-rose-900 rounded p-1 leading-none mt-1 text-sm">{error}</p>) : ('')}
                            {(success) ? (<p className="bg-emerald-50 border border-emerald-300 text-emerald-900 rounded p-1 leading-none mt-1 text-sm">{success}</p>) : ('')}
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <div className="mb-6">
                        {
                            /* No longer needed */
                            /*(show_screen == 'login') ? (
                                <>
                                    <span className="text-gray-700 text-base font-normal">Don&apos;t have an account?</span> <a onClick={() => set_show_screen('register')} className="text-link">Create account</a>
                                </>
                            ) : (
                                <>
                                    <span className="text-gray-700 text-base font-normal">Already have an account?</span> <a onClick={() => set_show_screen('login')} className="text-link">Log In</a>
                                </>
                            )*/
                        }

                        {(links) ? (
                            <div className="mt-8 ml-5">
                            <Link href="/privacy"><a className="text-link text-sm">Privacy Policy</a></Link>
                            <span className="inline-block text-gray-600 text-sm mx-2">•</span>
                            <Link href="/terms"><a className="text-link text-sm">Terms and Conditions</a></Link>
                            </div>
                        ) : ('')}
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
